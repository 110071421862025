import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../components/Core";

import PageWrapper from "../components/PageWrapper";
import { device } from "../utils";
import ContactForm from "../components/ContactForm";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { graphql } from "gatsby";

const WidgetWrapper = styled(Box)`
  border-radius: 10px;
  background-color: #f7f7fb;
  padding-top: 80px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  @media ${device.lg} {
    padding-left: 140px;
    padding-right: 150px;
  }
  @media ${device.xl} {
    padding-left: 150px;
    padding-right: 150px;
  }
`;

const Contact = ({ data }) => {
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <div className="banner-content">
                  <Text>
                    <MDXRenderer>{data.page.body}</MDXRenderer>
                  </Text>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center mt-5 pt-lg-5">
              <Col xl="10">
                <WidgetWrapper>
                  <Row>
                    <Col md="6" sm="6">
                      <Box className="mb-5">
                        <Title variant="card" fontSize="24px">
                          Call me
                        </Title>
                        <Text>
                          <a href="tel:+61451 515 197">0451 515 197</a>
                        </Text>
                      </Box>
                    </Col>
                    <Col md="6" sm="6">
                      <Box className="mb-5">
                        <Title variant="card" fontSize="24px">
                          Email me
                        </Title>
                        <Text>
                          <a href="mailto:hello@christinecolman.com.au">
                            hello@christinecolman.com.au
                          </a>
                        </Text>
                      </Box>
                    </Col>
                  </Row>
                </WidgetWrapper>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg={6} className="order-lg-1 pt-5 mt-4">
                <div>
                  <Title
                    variant="card"
                    fontSize="24px"
                    className="mb-5 text-center"
                  >
                    or send me a message
                  </Title>
                  <ContactForm formName="contact-contact-page" />
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Contact;

export const query = graphql`
  query {
    page: mdx(frontmatter: { slug: { eq: "contact" } }) {
      body
    }
  }
`;
